<template>
  <div>
    <file-pond
        ref="pond"
        :allow-reorder="true"
        :label-idle="labelIdle"
        :server="server"
        credits=""
        name="file"
        @processfile="processFile"
        @removefile="removeFile"
        v-on:init="handleFilePondInit"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  name: 'DocumentUpload',
  components: {FilePond},
  props: {
    labelIdle: {
      default: ''
    },
    endpoint: {
      required: true
    },
    exitingFile:{

    }
  },
  data () {
    return {
      actualFile: false,
      actionUrl: ''
    }
  },
  methods: {
    handleFilePondInit () {
      this.$refs.pond.getFiles()
    },
    processFile (error, file) {
      if (!error) {
        this.actualFile = {
          fileId: file.id,
          imageId: file.serverId
        }
      } else {
        this.actualFile = false
      }
    },
    removeFile () {
      this.actualFile = false
    },
    clearImages () {
      this.$refs.pond.removeFiles()
    }
  },
  computed: {
    apiUrl () {
      return `${this.actionUrl}${this.endpoint}`
    },
    server () {
      return {
        url: this.apiUrl,
        process: {
          headers: {
            'Accept': 'application/json',
            'Language': 'ro',
            'Authorization': `Bearer ${this.token}`
          }
        },
        revert: {
          headers: {
            'Accept': 'application/json',
            'Language': 'ro',
            'Authorization': `Bearer ${this.token}`
          }
        }
      }
    },
    token () {
      return this.$store.getters['auth/token']
    }
  },
  watch: {
    actualFile: {
      deep: true,
      handler () {
        this.$emit('uploaded', this.actualFile)
      }
    }
  },
  created () {
    const baseURL = process.env.VUE_APP_BASE_URL_API
    this.actionUrl = `${baseURL}/app/`
  }
}
</script>
