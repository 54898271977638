<template>
 <span class="button-wrapper">
   <v-dialog v-model="dialogDisplay" max-width="350" persistent>
      <v-card>
        <v-card-title class="justify-center">Esti sigur ca vrei sa anulezi comanda?</v-card-title>
        <v-card-actions>
          <v-btn :disabled="loading" color="blue darken-1" text @click="dialogDisplay=false">Renunta la anulare</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading" text @click="triggerAction">Anuleaza comanda</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   <vs-tooltip position="right" text="Anuleaza comanda">
    <v-btn color="error" @click="dialogDisplay = true">
      <i class="fal fa-trash-alt"></i> <span class="ml-2">Anuleaza comanda</span>
    </v-btn>
   </vs-tooltip>
  </span>
</template>

<script>
export default {
  name: 'CancelOrderButton',
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      dialogDisplay: false,
      loading: false
    }
  },
  methods: {
    triggerAction () {
      this.loading = true
      this.$http.delete(`orders/${this.order.id}`)
        .then(() => {
          this.$router.push({name: 'orders-all', params: {status: 'unprocessed'}})
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.dialogDisplay = false
        })
    }
  }
}
</script>


<style scoped>
.button-wrapper {
  @apply inline-block;
}
</style>

