<template>
  <div>
    <vs-tooltip position="right" :text="hasDocumentsUploaded ? 'Inchide comanda si plata ramburs'
      : 'Incarcati factura si certificatul de garantie!'">
      <v-btn :disabled="loading || !hasDocumentsUploaded" @click="sendChangeRequest">Plata Ramburs</v-btn>
    </vs-tooltip>
  </div>
</template>

<script>
export default {
  name: 'MarkAsPaiedOnDelivery',
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    hasDocumentsUploaded () {
      return this.order.invoice != null && this.order.warranty != null;
    }
  },
  methods: {
    sendChangeRequest () {
      this.loading = true
      this.$http.patch(`orders/${this.order.id}/mark-as-paid-on-delivery`)
        .then(() => {
          this.$router.push({
            name: 'orders-all',
            params: {status: 'unprocessed'}
          })
        })
        .catch((error) => {
            this.$vs.notify({
              title: 'Eroare',
              text: error.response.data.message,
              color: 'danger'
            });
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
