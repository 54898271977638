<template>
  <section>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            Nr. Comanda:
            <strong>
              {{ order.order_number }}
              <v-chip
                v-if="order.status"
                :color="order.status_details.color"
                class="ml-4"
                small
              >
                {{ order.status_details.name }}
              </v-chip>
            </strong>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Detalii Livrare</v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Persoana de contact</td>
                    <td>{{ order.shipping_contact_name }}</td>
                  </tr>
                  <tr>
                    <td>Nr telefon per. contact</td>
                    <td>{{ order.shipping_contact_phone }}</td>
                  </tr>
                  <tr>
                    <td>Adresa de livrare</td>
                    <td>{{ order.shipping_address }}</td>
                  </tr>
                  <tr>
                    <td>Judet</td>
                    <td>
                      <template v-if="order.shipping_county">
                        {{ order.shipping_county.name }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>Oras</td>
                    <td>
                      <template v-if="order.shipping_city">
                        {{ order.shipping_city.name }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Date Facturare</v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Personalitate</td>
                    <td>
                      <template v-if="order.billing_is_company"
                        >Persoana Juridica</template
                      >
                      <template v-else>Persoana Fizica</template>
                    </td>
                  </tr>
                  <template v-if="order.billing_is_company">
                    <tr>
                      <td>Nume firma</td>
                      <td>{{ order.billing_company_name }}</td>
                    </tr>
                    <tr>
                      <td>Cui</td>
                      <td>{{ order.billing_vat }}</td>
                    </tr>
                    <tr>
                      <td>Nr. Reg. Com.</td>
                      <td>{{ order.billing_reg_number }}</td>
                    </tr>
                  </template>
                  <tr>
                    <td>Nume</td>
                    <td>{{ order.billing_name }}</td>
                  </tr>
                  <tr>
                    <td>Nr. Telefon</td>
                    <td>{{ order.billing_phone_number }}</td>
                  </tr>
                  <tr v-if="order.customer">
                    <td>Email</td>
                    <td>{{ order.customer.email }}</td>
                  </tr>
                  <tr>
                    <td>Adresa</td>
                    <td>{{ order.billing_address }}</td>
                  </tr>
                  <tr>
                    <td>Judet</td>
                    <td>
                      <template v-if="order.billing_county">
                        {{ order.billing_county.name }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>Oras</td>
                    <td>
                      <template v-if="order.billing_city">
                        {{ order.billing_city.name }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Rezumat</v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Modalitate plata</td>
                    <td>
                      <template v-if="order.payment">
                        {{ order.payment.name }}
                      </template>
                    </td>
                  </tr>

                  <tr>
                    <td>Metoda de transport</td>
                    <td>
                      <div>
                        <template v-if="order.delivery">
                          {{ order.delivery.name }}
                        </template>
                      </div>
                      <div v-if="order.has_saturday_delivery">
                        ,Livrare sambata, {{ order.saturday_delivery }} Lei
                      </div>
                      <div v-if="order.has_delivery === 1">
                        Transport: {{ order.delivery_cost }} lei
                      </div>
                      <div v-else>Livrare gratuita</div>
                      <div v-if="order.has_insurance">
                        Are asigurare ({{ order.insurance }} Lei)
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Total Produse</td>
                    <td>{{ order.total_products }} Lei</td>
                  </tr>

                  <tr>
                    <td>Taxa verde</td>
                    <td>{{ order.total_green_tax }} Lei</td>
                  </tr>

                  <tr>
                    <td>TVA</td>
                    <td>{{ order.total_vat }} Lei</td>
                  </tr>

                  <tr>
                    <td>Total</td>
                    <td>{{ order.total }} Lei</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Produse</v-card-title>
          <v-card-text>
            <v-data-table
              :disable-pagination="true"
              :headers="productTableHeaders"
              :hide-default-footer="true"
              :items="order.items"
            >
              <template v-slot:item.name="{ item }">
                <template v-if="item.product != null">
                  <template v-if="item.product.is_deleted">Sters:</template>
                  <a
                    :class="{ 'line-through': item.product.is_deleted }"
                    :href="item.product.absolute_permalink"
                    target="_blank"
                  >
                    {{ item.name }}
                    <small>{{ item.group }}</small>
                  </a>
                </template>
                <template v-else>
                  <span>
                    {{ item.name }}
                    <small>{{ item.group }}</small>
                  </span>
                </template>

                <v-alert
                  v-if="
                    typeof item.warranties !== 'undefined' &&
                    item.warranties.length
                  "
                  border="left"
                  class="my-4"
                  color="primary"
                  colored-border
                  elevation="2"
                  style="font-size: 10px"
                >
                  <table class="w-full">
                    <tr>
                      <th colspan="2">Garantii:</th>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Cantitate</td>
                      <td>Pret</td>
                    </tr>
                    <tr
                      v-for="(warranty, index) in item.warranties"
                      :key="index"
                    >
                      <td>{{ warranty.name }}</td>
                      <td>{{ warranty.quantity }}</td>
                      <td>{{ warranty.total_price }}</td>
                    </tr>
                  </table>
                </v-alert>

                <v-alert
                  v-if="
                    typeof item.extraOptions !== 'undefined' &&
                    item.extraOptions.length
                  "
                  border="left"
                  class="my-4"
                  color="primary"
                  colored-border
                  elevation="2"
                  style="font-size: 10px"
                >
                  <table class="w-full">
                    <tr>
                      <th colspan="2">Extra optiuni:</th>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Cantitate</td>
                      <td>Pret</td>
                    </tr>
                    <tr
                      v-for="(extraOption, index) in item.extraOptions"
                      :key="index"
                    >
                      <td>{{ extraOption.name }}</td>
                      <td>{{ extraOption.quantity }}</td>
                      <td>{{ extraOption.total_price }}</td>
                    </tr>
                  </table>
                </v-alert>

                <v-alert
                  v-if="typeof item.gifts !== 'undefined' && item.gifts.length"
                  border="left"
                  class="my-4"
                  color="yellow"
                  colored-border
                  elevation="2"
                  style="font-size: 10px"
                >
                  <table class="w-full">
                    <tr>
                      <th colspan="2">Cadouri:</th>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Cantitate</td>
                    </tr>
                    <tr v-for="(gift, index) in item.gifts" :key="index">
                      <td>{{ gift.name }}</td>
                      <td>{{ gift.quantity }}</td>
                    </tr>
                  </table>
                </v-alert>
              </template>

              <template v-slot:item.featured_image="{ item }">
                <template v-if="item.product">
                  <a :href="item.product.absolute_permalink" target="_blank">
                    <img
                      :src="item.product.featured_image.src"
                      alt=""
                      width="50"
                    />
                  </a>
                </template>
              </template>

              <template v-slot:item.quantity="{ item }">
                <template v-if="order.is_editable">
                  <order-item-quantity
                    :order-item="item"
                    @saved="updateOrder"
                  />
                </template>
                <template v-else>{{ item.quantity }}</template>
              </template>
              <template v-slot:item.actions="{ item }">
                <template v-if="order.is_editable">
                  <delete-button
                    v-show="order.items.length > 1"
                    @delete="deleteItem(item)"
                  />
                  <help-icon
                    v-if="order.items.length <= 1"
                    position="bottom"
                    text="Comanda nu poate avea 0 produse"
                  />
                </template>
              </template>

              <template v-slot:footer>
                <v-row v-if="order.is_editable" class="mt-2">
                  <v-col class="text-right">
                    <add-new-product-to-order
                      :order="order"
                      @updated="updateOrder"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col>
                    <table class="ml-auto text-right total-table">
                      <tr>
                        <td>Total Produse:</td>
                        <td>
                          <strong>{{ order.total_products }}</strong> Lei
                        </td>
                      </tr>
                      <tr>
                        <td>Taxa verde:</td>
                        <td>
                          <strong>{{ order.total_green_tax }}</strong> Lei
                        </td>
                      </tr>

                      <tr v-if="order.has_delivery === 1">
                        <td>Transport</td>
                        <td>
                          <strong>{{ order.delivery_cost }}</strong> Lei
                        </td>
                      </tr>

                      <tr v-if="order.has_saturday_delivery === 1">
                        <td>Livrare sambata</td>
                        <td>
                          <strong>{{ order.saturday_delivery }}</strong> Lei
                        </td>
                      </tr>

                      <tr v-if="order.has_insurance">
                        <td>Asigurare transport</td>
                        <td>
                          <strong>{{ order.insurance }}</strong> Lei
                        </td>
                      </tr>

                      <tr>
                        <td>TVA:</td>
                        <td>
                          <strong>{{ order.total_vat }}</strong> Lei
                        </td>
                      </tr>

                      <tr>
                        <td>Total comanda:</td>
                        <td>
                          <strong>{{ order.total }}</strong> Lei
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Detalii comanda </v-card-title>
          <v-card-text>
            <div v-html="order.extra_details"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="orderIsNotNewByAgent">
      <v-col>
        <v-card>
          <v-card-title>Documente</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <div class="font-weight-bold">Factura finala</div>
                <template v-if="order.invoice">
                  <v-btn :href="order.invoice.document_url" target="_blank"
                    >Descarca Factura</v-btn
                  >
                </template>
                <template v-else-if="order.is_editable">
                  <document-upload
                    :endpoint="`orders/${order.id}/invoices`"
                    label-idle="Incarcare Factura finala"
                    @uploaded="loadOrder"
                  />
                </template>
                <template v-else> - </template>
              </v-col>
              <v-col>
                <div class="font-weight-bold">Garantie</div>
                <template v-if="order.warranty">
                  <v-btn :href="order.warranty.document_url" target="_blank"
                    >Descarca Garantia</v-btn
                  >
                </template>
                <template v-else-if="order.is_editable">
                  <document-upload
                    :endpoint="`orders/${order.id}/warranty`"
                    label-idle="Incarcare Garantie"
                    @uploaded="loadOrder"
                  />
                </template>
                <template v-else> - </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="orderIsNotNewByAgent">
      <v-col>
        <v-card>
          <v-card-title>Factura proforma</v-card-title>
          <v-card-text>
            <div class="flex">
              <send-proforma-button
                :order="order"
                class="mr-4"
                @should-refresh="updateOrder"
              />
              <template v-if="order.proforma">
                <download-proforma-button :order-id="orderId" />
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!order.is_canceled">
      <v-col>
        <v-card>
          <v-card-title>Actiuni</v-card-title>
          <v-card-text>
            <div class="flex -mx-2">
              <template v-if="order.is_editable && orderIsNotNewByAgent">
                <confirm-payment-button :order="order" @confirmed="loadOrder" />
                <mark-as-pay-button
                  :order="order"
                  class="mx-2"
                  @updated="loadOrder"
                />
                <mark-as-paied-on-delivery
                  :order="order"
                  class="mx-2"
                  @updated="loadOrder"
                />
                <v-spacer />
                <cancel-order-button :order="order" @confirmed="loadOrder" />
              </template>
              <template v-else-if="orderIsNewByAgent">
                <agent-confirms-order-button
                  :order="order"
                  @confirmed="loadOrder"
                />
              </template>
              <template v-else>
                Comanda este marcata ca {{ orderStatusName }}. Nu se mai pot
                realiza acțiuni pe ea.
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="orderIsNotNewByAgent">
      <template v-show="order.delivery.has_awb">
        <order-awb-code :order="order" />
      </template>
    </template>
  </section>
</template>

<script>
import OrderItemQuantity from "./components/OrderItemQuantity";
import AddNewProductToOrder from "./components/AddNewProductToOrder";
import SendProformaButton from "./components/SendProformaButton";
import DownloadProformaButton from "./components/DownloadProformaButton";
import DocumentUpload from "./components/DocumentUpload";
import MarkAsPayButton from "./components/MarkAsPayButton";
import MarkAsPaiedOnDelivery from "./components/MarkAsPaiedOnDelivery";
import ConfirmPaymentButton from "./components/ConfirmPaymentButton";
import OrderAwbCode from "./components/OrderAwbCode";
import DeleteButton from "../../components/general-form/DeleteButton";
import AgentConfirmsOrderButton from "@/views/Orders/components/AgentConfirmsOrderButton";
import HelpIcon from "@/components/HelpIcon";
import CancelOrderButton from "@/views/Orders/components/CancelOrderButton";

export default {
  name: "OrderPage",
  components: {
    CancelOrderButton,
    HelpIcon,
    AgentConfirmsOrderButton,
    DeleteButton,
    OrderAwbCode,
    ConfirmPaymentButton,
    MarkAsPaiedOnDelivery,
    MarkAsPayButton,
    DocumentUpload,
    DownloadProformaButton,
    SendProformaButton,
    AddNewProductToOrder,
    OrderItemQuantity,
  },
  data() {
    return {
      order: {},
      productTableHeaders: [
        {
          align: "start",
          text: "Imagine",
          value: "featured_image",
          sortable: false,
        },
        {
          align: "start",
          text: "Produs",
          value: "name",
          sortable: false,
        },
        {
          align: "start",
          text: "Pret",
          value: "price",
          sortable: false,
        },
        {
          align: "start",
          text: "TVA",
          value: "vat",
          sortable: false,
        },
        {
          align: "start",
          text: "Cantitate",
          value: "quantity",
          sortable: false,
        },
        {
          align: "start",
          text: "Pret total",
          value: "total_price",
          sortable: false,
        },
        {
          align: "start",
          text: "Pret total (VAT inclus)",
          value: "total_price_with_vat",
          sortable: false,
        },
        {
          align: "start",
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    orderIsNotNewByAgent() {
      return !this.orderIsNewByAgent;
    },
    orderIsNewByAgent() {
      return this.order.status === "ordered_by_agent";
    },
    orderStatusName() {
      if (typeof this.order.status_details === "undefined") {
        return "";
      }
      return this.order.status_details.name;
    },
    orderId() {
      return this.$route.params.id;
    },
    customerId() {
      return this.order.customer_id;
    },
  },
  methods: {
    sending() {
      setTimeout(function () {
        alert("error ");
      }, 1000);
    },
    updateOrder(newOrder) {
      this.order = newOrder;
    },
    deleteItem(item) {
      this.$store.dispatch("startLoading");
      this.$http
        .delete(`orders/${this.orderId}/${item.id}`)
        .then(({ data }) => {
          this.updateOrder(data);
          this.$store.dispatch("stopLoading");
        })
        .catch(() => {
          this.$vs.notify({
            title: "Eroare",
            text: "Eroare la stergere, va rugam reimprospatati pagina sau incercati mai tarziu",
            color: "danger",
          });
        });
    },
    loadOrder() {
      this.$store.dispatch("startLoading");
      this.$http
        .get(`orders/${this.orderId}`)
        .then(({ data }) => {
          this.updateOrder(data);
          this.$store.dispatch("stopLoading");
        })
        .catch(() => {
          this.$vs.notify({
            title: "Eroare",
            text: "Eroare la incarcare, va rugam reimprospatati pagina sau incercati mai tarziu",
            color: "danger",
          });
        });
    },
  },
  created() {
    this.loadOrder();
  },
};
</script>

<style lang="scss" scoped>
.total-table {
  tr {
    td {
      padding: 5px;
    }
  }
}

.v-card {
  height: 100%;
}
</style>
